import { getHost } from '!app/lib/config';
import {
  ANONYMOUS_USER_ID,
  COOKIES,
  STATUS_CODES,
  HULU_SESSION,
  HULU_PGID,
} from '!app/lib/constants';
import { getCookie } from '!app/lib/cookies';

export const getName = async (cookie, context, fallback = 'ACCOUNT') => {
  const userInfo = await getUserInfo(cookie, context);
  let name;
  const profiles = userInfo?.profiles ?? '';

  if (profiles && profiles.length > 0) {
    const profile = getCurrentProfile(profiles, context);
    name = profile?.name;
  }

  name = name || userInfo?.first_name || fallback;

  return name;
};

export const isLoggedIn = (context) => {
  return getUserID(context) > ANONYMOUS_USER_ID;
};

// if user subscribes and has entitlements
export const isSubscriber = (req) => {
  return (
    (req?.cookies?.[HULU_SESSION] ?? false) && req?.cookies?.[HULU_PGID] > 1
  );
};

/**
 * Check if user is a classic
 * @param {Object} context from Splat
 * @return {boolean} if user has a paused subscription
 */
export const isClassicSubscriber = (context) => {
  return isLoggedIn(context) && !isSubscriber(context);
};

export const getUserID = (context) => {
  const uid = getCookie(COOKIES.HULU_UID, context);

  return uid ? parseInt(uid, 10) : ANONYMOUS_USER_ID;
};

export const getUserInfo = async (cookie, context) => {
  if (!isLoggedIn(context)) {
    return null;
  }

  let result = null;
  try {
    const res = await fetch(`${getHost('home')}/v1/users/self`, {
      headers: { cookie },
      credentials: 'include',
    });

    if (res.status !== STATUS_CODES.OK) return null;

    result = await res.json();
  } catch (err) {
    return null;
  }
  return result;
};

export const getCurrentProfile = (profiles, context) => {
  const profileId = getCookie(COOKIES.PROFILE_ID, context);

  return profileId ? profiles.find((p) => p.id === profileId) : profiles[0];
};
