import get from 'lodash/get';

import { tokenizeCartAbandonmentCopy } from './utils/cartAbandonmentUtils';

import { getHost } from '!app/lib/config';
import {
  ANON,
  PARTIALLY_ENTITLED,
  PARTIALLY_ENTITLED_NOT_TO_ADDONS,
  PARTIALLY_ENTITLED_NOT_ENROLLED,
  FULLY_ENTITLED,
  INELIGIBLE,
  INACTIVE,
  PREMIUM_NETWORKS,
  PROGRAMS_WITH_CODE,
  PROGRAM_TYPE_HUMAN_CODE,
  PROGRAM_TYPE_PARTNER_CODE,
  PROGRAM_TYPE_DECRYPTED_CODE,
  PROGRAM_TYPE_VIP_CODE,
  PROGRAM_TYPE_DEVICE_CODE,
} from '!app/lib/constants';
import {
  getHostname,
  getProtocol,
  getLocationAndExtras,
} from '!app/lib/urlUtils';

import 'isomorphic-fetch';

const PROGRAM_DEVICE = 'Device';

export const getFromParameter = () => {
  const from = window.location.pathname
    .toLowerCase()
    .replace('/mobile', '')
    .replace('/start', '')
    .replace(/\//g, '');
  return from;
};

export const getPartnerCode = () => {
  return window.location.pathname.split('/').pop();
};

export const getRedirectionQueries = ({
  params,
  customFrom,
  passUrlAsProgram,
}) => {
  // if passUrlAsProgram is true and no customFrom value has been provided,
  // set the "from" parameter to the value of the page URL
  // otherwise, set the "from" parameter to be an empty string
  const from = customFrom || (passUrlAsProgram ? getFromParameter() : '');
  const queries = [];
  // Check if the start is a partner page and split the partner code out
  if (from) {
    if (from.startsWith('partner')) {
      queries.push(['from', 'partner']);
      // get the end of the url
      queries.push(['partner_code', getPartnerCode()]);
    } else {
      queries.push(['from', `${from}`]);
    }
  }
  if (params) {
    Object.keys(params).forEach((param) =>
      queries.push([param, params[param]])
    );
  }

  const query = queries.map((pair) => pair.join('=')).join('&');

  const currentSearch =
    window.location.search && window.location.search.substring(1);

  if (!query && currentSearch) {
    return `?${currentSearch}`;
  }

  if (query && !currentSearch) {
    return `?${query}`;
  }

  return query ? `?${query}&${currentSearch}` : '';
};

export const validateCodeFromSignup = async (type, code) => {
  const from = getFromParameter();
  return await fetch(`${getHost('signup')}/api/v2/codes/validate`, {
    mod: 'no-cors',
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    body: JSON.stringify({ type, code, from }),
  });
};

export const goToSignup = (params, path, user, from, passUrlAsProgram) => {
  const entitlementState = get(user, 'entitlementState', ANON);
  const redirectionQueries = getRedirectionQueries({
    params,
    customFrom: from,
    passUrlAsProgram,
  });
  const signupPath = (path || '').replace(/^\/signup/g, '');

  const [urlLoc, urlQuery, urlAnchor] = getLocationAndExtras(signupPath);

  let urlQueryWithSeparators = '';
  // if we have redirection queries to pass
  // add a '&' separator onto the front of urlQuery
  // else if we do not have redirection queries to pass
  // add a '?' delimiter to the front of the urlQuery
  if (redirectionQueries && urlQuery) {
    urlQueryWithSeparators = `&${urlQuery}`;
  } else if (!redirectionQueries && urlQuery) {
    urlQueryWithSeparators = `?${urlQuery}`;
  }

  if (entitlementState === PARTIALLY_ENTITLED) {
    // take them to manage their addons if partially entitled
    window.location = `${getHost(
      'hudis'
    )}/account/addons${redirectionQueries}${urlQueryWithSeparators}${urlAnchor}`;
  } else if (entitlementState === FULLY_ENTITLED) {
    // take them to site home if fully entitled
    window.location = getHostnameWithProtocol();
  } else {
    window.location = `${getHost('signup')}${
      !urlLoc.startsWith('/') ? '/' : ''
    }${urlLoc}${redirectionQueries}${urlQueryWithSeparators}${urlAnchor}`;
  }
};

export const getCtaText = ({
  user,
  programType,
  componentNonSubCta,
  componentSubCta,
  componentAllCta,
  locale,
  ctaFields,
  cartAbandonment,
}) => {
  const {
    ctaAnonCopy,
    ctaSomeCopy,
    ctaSomeNotToAddonsCopy,
    ctaSomeAndNotEnrolledCopy,
    ctaAllCopy,
    ctaIneligibleCopy,
    ctaInactiveCopy,
    ctaAppleCopy,
    ctaMobileLink,
    cartAbandonmentCopy,
  } = ctaFields;

  const tokenizedCartAbandonmentCopy = tokenizeCartAbandonmentCopy(
    cartAbandonment,
    cartAbandonmentCopy
  );

  if (cartAbandonment && tokenizedCartAbandonmentCopy) {
    return tokenizedCartAbandonmentCopy;
  }

  const isSubscriber = get(user, 'isSubscriber', false);
  const entitlementState = get(user, 'entitlementState', 0);
  const isAppleBilled = get(user, 'isAppleBilled', false);

  if (ctaAppleCopy && isAppleBilled) {
    return ctaAppleCopy;
  }

  let nonSubCta = null;
  let subCta = null;
  let allCta = null;

  nonSubCta = componentNonSubCta || ctaAnonCopy || null;
  if (PROGRAMS_WITH_CODE.includes(programType)) {
    nonSubCta = componentNonSubCta || 'SIGN UP NOW';
  }

  if (locale === 'en-US' && isSubscriber) {
    subCta = componentSubCta || ctaSomeCopy || 'MANAGE MY ACCOUNT';
    allCta = componentAllCta || ctaAllCopy || 'START WATCHING';

    if (ctaMobileLink) allCta = 'DOWNLOAD HULU APP';
  } else if (isSubscriber) {
    subCta = componentSubCta || 'MANAGE MY ACCOUNT';
    allCta = componentAllCta || componentSubCta || 'START WATCHING';
  }

  // Hardcoded since Masthead does not support alternative CTA copy
  if (programType === PROGRAM_TYPE_VIP_CODE) {
    subCta = 'START HERE';
    allCta = 'START HERE';
  }

  if (isSubscriber && programType === PROGRAM_TYPE_HUMAN_CODE) {
    subCta = 'APPLY CODE';
    allCta = 'APPLY CODE';
  }

  const ctaCopyStates = {
    [ANON]: nonSubCta,
    [PARTIALLY_ENTITLED]: subCta,
    [PARTIALLY_ENTITLED_NOT_TO_ADDONS]: ctaSomeNotToAddonsCopy || subCta,
    [PARTIALLY_ENTITLED_NOT_ENROLLED]: ctaSomeAndNotEnrolledCopy,
    [FULLY_ENTITLED]: allCta,
    [INELIGIBLE]: ctaIneligibleCopy,
    [INACTIVE]: ctaInactiveCopy,
  };

  return ctaCopyStates[entitlementState];
};

export const getCtaPath = ({ user, componentUrl, ctaFields }) => {
  const entitlementState = get(user, 'entitlementState', 0);
  const isAppleBilled = get(user, 'isAppleBilled', false);
  const {
    ctaAnonLink,
    ctaSomeLink,
    ctaSomeNotToAddonsLink,
    ctaSomeAndNotEnrolledLink,
    ctaAllLink,
    ctaIneligibleLink,
    ctaInactiveLink,
    ctaAppleLink,
    ctaMobileLink,
  } = ctaFields;

  if (ctaAppleLink && isAppleBilled)
    return `${getHost('hudis')}${ctaAppleLink}`;

  if (componentUrl) return componentUrl;

  const ctaSomeLinkPath = ctaSomeLink
    ? `${getHost('hudis')}${ctaSomeLink}`
    : ctaSomeLink;
  const ctaLinkStates = {
    [ANON]: ctaAnonLink,
    [PARTIALLY_ENTITLED]: ctaSomeLinkPath,
    [PARTIALLY_ENTITLED_NOT_TO_ADDONS]:
      ctaSomeNotToAddonsLink || ctaSomeLinkPath,
    [PARTIALLY_ENTITLED_NOT_ENROLLED]: ctaSomeAndNotEnrolledLink,
    [FULLY_ENTITLED]: ctaMobileLink || ctaAllLink,
    [INELIGIBLE]: ctaIneligibleLink
      ? `${getHost('hudis')}${ctaIneligibleLink}`
      : ctaIneligibleLink,
    [INACTIVE]: ctaInactiveLink,
  };

  return ctaLinkStates[entitlementState] || '';
};

export const isCodeRequired = (programType, requireDeviceCode = false) => {
  if (programType === PROGRAM_DEVICE) {
    return requireDeviceCode;
  }

  return (
    Boolean(programType) &&
    PROGRAMS_WITH_CODE.filter((program) => program !== PROGRAM_DEVICE).includes(
      programType
    )
  );
};

export const getInputPlaceholder = (programType) => {
  switch (programType) {
    case PROGRAM_TYPE_HUMAN_CODE:
    case PROGRAM_TYPE_PARTNER_CODE:
    case PROGRAM_TYPE_DECRYPTED_CODE:
      return 'Promo Code';
    case PROGRAM_TYPE_VIP_CODE:
      return 'Enter Code';
    case PROGRAM_TYPE_DEVICE_CODE:
      return 'Device Activation Code';
  }
  return null;
};

/**
 * Generates login URL
 * @param {boolean} isHuluUser
 * @param {string} referringPath
 */
export const generateLogin = ({ isHuluUser, asPath, url, pathname }) => {
  const referringURL = encodeURIComponent(
    `${getHostnameWithProtocol(url)}${asPath || pathname}`
  );
  return !isHuluUser
    ? `${getHost('hoth')}/web/login?next=${referringURL}`
    : '/account';
};

export const getHostnameWithProtocol = (url) => {
  const hostname = getHostname(url);
  const protocol = getProtocol(url);
  return `${protocol}//${hostname}`;
};

/**
 * Formats premium network string for SUF. PREMIUM_NETWORKS object is assumed
 * to contain each premium network's SUF value.
 * @param {boolean} requirePremium
 * @param {string} network
 *
 * @return {string or undefined} formatted network string
 */
export const getPremiumNetworkFromParameter = (requirePremium, network) => {
  if (!requirePremium || typeof network !== 'string') {
    return undefined;
  }

  return PREMIUM_NETWORKS?.[network.toLowerCase()]?.program;
};

/**
 * Check if cta path starts with `data-target= `indicating an intention of overriding
 * default CTA redirect behavior with a trigger of a modal
 * @param {String} ctaPath CTA url/path
 * @return {string|undefined} data target path
 */
export const getDataTargetOverride = (ctaPath) => {
  const hasCtaDataTargetOverride =
    typeof ctaPath === 'string' && ctaPath.startsWith('data-target=');
  return hasCtaDataTargetOverride ? ctaPath.split('=')[1] : undefined;
};
