import includes from 'lodash/includes';
import MobileDetect from 'mobile-detect';

import { DEV_STAGING_ENVS } from '!app/lib/constants';

/**
 * @returns {string} current process environment
 */
const getEnvironment = () => process.env.HULU_ENV || 'development';

const PRODUCTION_ENVS = Object.freeze(['production']);

/**
 * check if the current process environment is production
 * @returns {boolean}
 */
const isProductionEnvironment = () =>
  includes(PRODUCTION_ENVS, getEnvironment());

const SMOKE_ENVS = Object.freeze(['smoke']);

/**
 * check if the current process environment is smoke
 * @returns {boolean}
 */
const isSmokeEnvironment = () => includes(SMOKE_ENVS, getEnvironment());

/**
 * check if the current process environment is discover beta
 * @returns {boolean}
 */
const isDevelopProdEnvironment = () =>
  includes(['develop_prod'], getEnvironment());

/**
 * check if the current process environment is discover beta
 * @returns {boolean}
 */
const isDiscoverBetaEnvironment = () =>
  includes(['prod_discover_beta'], getEnvironment());

/**
 * check if the current process environment is test staging
 * @returns {boolean}
 */
const isTestStaging = () => includes(['test_staging'], getEnvironment());

/**
 * check if the current process environment is staging
 * @returns {boolean}
 */
const isStagingEnvironment = () => includes(['staging'], getEnvironment());

/**
 * check if the current process environment is staging
 * @returns {boolean}
 */
const isDevelopmentEnvironment = () =>
  includes(['development'], getEnvironment());

/**
 * check if the current process environment is development or staging
 * @returns {boolean}
 */
const isDevOrStagingEnvironment = () =>
  includes(DEV_STAGING_ENVS, getEnvironment());

/**
 * check if the current node process environment is NOT production.
 * @returns {boolean}
 */
const isNodeDevMode = () => process.env.NODE_ENV !== 'production';

/**
 * return true if in the browser
 * @returns {boolean} is this a browser environment
 */
const isBrowser = () => typeof global.window !== 'undefined';

const mobileDetect = () =>
  new MobileDetect(isBrowser() ? global.navigator.userAgent : null);

const getHostnameByEnvironment = () => {
  if (isDevelopmentEnvironment() || isStagingEnvironment() || isTestStaging()) {
    return 'www.huluqa.com';
  }
  if (isDevelopProdEnvironment()) {
    return 'www.develop.prod.hulu.com';
  }
  if (isDiscoverBetaEnvironment()) {
    return 'www.discover-beta.hulu.com';
  }
  if (isSmokeEnvironment()) {
    return 'www.smoke.hulu.com';
  }
  return 'www.hulu.com';
};

export {
  getEnvironment,
  isProductionEnvironment,
  isSmokeEnvironment,
  isDevOrStagingEnvironment,
  isNodeDevMode,
  isBrowser,
  mobileDetect,
  getHostnameByEnvironment,
  PRODUCTION_ENVS,
  SMOKE_ENVS,
  DEV_STAGING_ENVS,
};
