/**
 * Gets display name for given HoC.
 * @param {string} hocName
 * @param {React.Component} WrappedComponent wrapped component of given HoC.
 * @return {string} name of component
 */
const getDisplayName = (hocName, WrappedComponent) => {
  return `${hocName}(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;
};

/**
 * Gets default `getInitialProps` implementation which just pass along context and props.
 * @param {function(new: T)} PageComponent
 * @return {function(ContextObject: context): T}
 * @template T
 */
const defaultGetInitialProps = (PageComponent) => async (context) => {
  const getProps = PageComponent.getInitialProps || (async () => ({}));
  return await getProps(context);
};

export { getDisplayName, defaultGetInitialProps };
