import get from 'lodash/get';

import {
  CollectionTabs,
  CollectionTabsMasthead,
  BaseCollection,
} from '!app/components/names';
import { isBrowser, mobileDetect } from '!app/lib/environment';
import { SCROLL_QUARTER_PERCENTILE } from '!app/metrics/constants';
import { fireUserInteraction } from '!app/metrics/fireEvent';

/**
 * Metrics uses 'mw' for mobile web, and 'web' for non-mobile-web.
 */
export function getMetricsDeviceType() {
  return mobileDetect().mobile() ? 'mv' : 'web';
}

/**
 * Given the current path for the user, return the page name for metrics.
 */
export function getMetricsPageName() {
  if (isBrowser()) {
    return window.location.pathname
      .replace(/(^\/|\/$)/g, '') // remove leading/trailing '/'
      .replace(/[-\/]/g, '_'); // replace other '/' and '-' with '_'
  }

  return null;
}

/**
 * Return how long a page load (page_impression) took.
 *
 * @param {bool} isFirstImpression Whether or not this is the first impression the app is firing.
 * @return How long it took for the browser to render the page.  We'll want to re-evaluate this as
 *         Hitch moves into a single-document, multi-page app.  If we _can't_ reliably calcuate the
 *         page-load time based on the first impression, _always_ return null.  That way any calculations
 *         we might try to make aren't skewed.
 */
export function getPageImpressionDuration(isFirstImpression) {
  // TODO (drew.hays, November 14, 2017): How can we time page loads after the first impresion?
  // This doesn't really need to be solved right now, since most of hitch's pages _are_
  // first impressions.
  if (!(isFirstImpression && isBrowser())) {
    return null;
  }

  const timing = window && window.performance && window.performance.timing;
  const end = timing && timing.domComplete;
  const start = timing && timing.fetchStart;

  // If we didn't have a start value that we could use, something is really wrong.
  // Go back to assuming a null-duration.
  if (!start || !end) {
    return null;
  }

  return end - start;
}

/**
 * Checks user agent and request query params to return whether analytics should be included
 * in the page or not.
 * @param {Object} req Express request object
 * @param {Object} [mobileDetectLib] Mobile detect library. Mainly used for testing.
 * @return {boolean} whether to incuded analytics or not
 */
export function shouldUseAnalytics(req, mobileDetectLib) {
  // Should default to the mobileDetect library unless you explicitly want to test this function.
  const mobileDetectFunc = mobileDetectLib || mobileDetect;
  const isSpeedInsights = mobileDetectFunc().match('Speed Insights');
  let useAnalytics = !isSpeedInsights;

  const forceAnalyticsParam = get(req, 'query.force_analytics') || false;
  if (forceAnalyticsParam && forceAnalyticsParam.toLowerCase() === 'true')
    useAnalytics = true;

  return useAnalytics;
}

/**
 * Counts the number of collections in the layout
 * @param {Component[]} components list coming from layout
 * @return {number} of collections
 */
export function countCollections(components) {
  return components.reduce((prev, curr) => {
    if (curr.type === CollectionTabs || curr.type === CollectionTabsMasthead) {
      return prev + get(curr, 'tabs', []).length;
    }
    if (curr.type === BaseCollection) {
      return prev + get(curr, 'collections', []).length;
    }
    return prev;
  }, 0);
}

/**
 * Map collection indices to collections and collection tabs
 * @param {Component[]} components list coming from layout
 * @return {Component[]} of the transformed components
 */
export function mapCollectionIndex(components) {
  let index = 0;
  return components.map((component) => {
    const metrics = {};
    const transformed = { ...component };
    if (
      component.type === CollectionTabs ||
      component.type === CollectionTabsMasthead
    ) {
      metrics.collectionIndex = index;
      index += component.tabs.length || 0;
    } else if (component.type === BaseCollection) {
      metrics.collectionIndex = index;
      ++index;
    }
    transformed.metrics = metrics;
    return transformed;
  });
}

/**
 * Get the formatted page uri
 * @param entityName name
 * @param entityId id
 * @param browsePageType page type, series or movies
 */
export function getDetailPageUri(entityName, entityId, browsePageType) {
  if (entityName && entityId && browsePageType) {
    return `${browsePageType}/${entityName}-${entityId}`;
  }
  return null;
}

/**
 * called in NavigationOneHulu onScroll
 * checks how far down the user has scrolled and sends the previous quarter HITs
 * @param previousThreshold where the page was before scroll
 * @param reachedQuartileIndex the index of the quarter in SCROLL_QUARTER_PERCENTILE the user has reached
 */
export const checkPreviousQuarters = (
  previousThreshold,
  reachedQuartileIndex
) => {
  const startingIndex = SCROLL_QUARTER_PERCENTILE.includes(previousThreshold)
    ? SCROLL_QUARTER_PERCENTILE.indexOf(previousThreshold)
    : SCROLL_QUARTER_PERCENTILE.length - 1;

  for (let index = startingIndex; index < reachedQuartileIndex; index++) {
    fireUserInteraction(
      'default',
      `scroll:${SCROLL_QUARTER_PERCENTILE[index]}`,
      'swipe',
      false
    );
  }
};
