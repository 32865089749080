// NOTE (drew.hays, November 13th, 2017):
// This is a solid rip-off of h3o-metrics:metricsTracker.js[1] with a few small
// details changed, because h3o-metrics didn't work as-is in hitch, due to some
// assumptions made in h3o-framework.  The goal would eventually be to rip
// this out and replace it with something in h3o-metrics.
//
// [1]: https://github.prod.hulu.com/SiteTeam/h3o-framework/blob/09046957343bdab9656166ff186cd526f1fa4710/packages/h3o-metrics/src/utils/metricsTracker.js

import { MetricsTracker } from '@hulu/site-metrics';
import get from 'lodash/get';
import noop from 'lodash/noop';
import getConfig from 'next/config';

import * as Environment from '!app/lib/environment';
import { LibLogger } from '!app/lib/logger';

let metricsTrackerInstance;

const APP_VERSION = process.env.npm_package_version;

const utils = {
  appComponentName: 'hitch',
  appComponentVersion: APP_VERSION,
  environmentUtils: {
    getEnvironment: () =>
      get(getConfig(), 'publicRuntimeConfig.huluEnv', 'development'),
    PRODUCTION_ENVS: ['production', 'plusprod'],
    getDogFoodFlavor: noop,
  },
  logger: LibLogger,
};

/**
 * Initialize a new metrics tracker, if one doesn't already exist. Return a promise
 * that will be resolved after the `application_start` event, if any, has been fired.
 *
 * @returns {Promise<void>}
 */
export async function initMetricsTracker(geodata = null) {
  if (!Environment?.isBrowser() || metricsTrackerInstance != null) {
    return Promise.resolve();
  }

  metricsTrackerInstance = new MetricsTracker({ ...utils });
  metricsTrackerInstance.setFeatureTag('non_sub_exp');
  if (geodata?.zip) {
    metricsTrackerInstance.setFeatureTag('channels_autofilled');
  }

  return metricsTrackerInstance.trackApplicationStart(global.performance);
}

/**
 * Send an event off to the metrics agent.
 *
 * @param {string} eventName The name of the event to track.
 * @param {object} [properties] Any properties that the event should be tracking.
 * @returns {Promise<void>}
 */
export function trackEvent(eventName, properties = {}) {
  if (Environment.isBrowser() && metricsTrackerInstance) {
    return metricsTrackerInstance.trackEvent(eventName, properties);
  }
  return Promise.resolve();
}

/**
 * Send a beacon off to the metrics agent.
 *
 * @param {string} eventName The name of the event to track.
 * @param {object} [properties] Any properties that the event should be tracking.
 * @returns {Promise<void>}
 */
export function trackAdAuditBeacon(eventName, properties = {}) {
  if (Environment.isBrowser() && metricsTrackerInstance) {
    return metricsTrackerInstance.trackAdAuditBeacon(eventName, properties);
  }
  return Promise.resolve();
}

/**
 * Get the original context from the metrics agent.
 *
 * @param {string} eventName The name of the event to track.
 * @param {object} [properties] Any properties that the event should be tracking.
 * @returns {Promise<void>}
 */
export function getContext() {
  if (Environment.isBrowser() && metricsTrackerInstance) {
    return metricsTrackerInstance.getCurrentCommonContext();
  }
  return {};
}
