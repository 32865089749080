import get from 'lodash/get';

import { getFromParameter, goToSignup, getCtaPath } from './signupUtils';

import { mobileAppUrls } from '!app/config';
import { apiPrefix, assetPrefix } from '!app/config/settings';
import { USER_AGENTS_REGEX } from '!app/lib/constants';
import {
  isBrowser,
  getHostnameByEnvironment,
  mobileDetect,
} from '!app/lib/environment';
import { DefaultLogger } from '!app/lib/logger';

const nodeUrl = require('url');

export const hitchUrl = (s3Url) => {
  const s3UrlObj = nodeUrl.parse(s3Url);
  return `${assetPrefix}s3${s3UrlObj.path}`;
};

/**
 * Returns an array of the url path, its query params, and its anchor.
 * The query string returned is not prefixed with '&' or '?'.
 * The anchor string returnd is not prefixed with '#'.
 *
 * @param {string} url string to be separated into path, params, and anchor
 * @returns {string[]} [url path, url query string, url anchor]
 */
export const getLocationAndExtras = (url) => {
  let urlLocation = url;
  let urlQuery = '';
  let urlAnchor = '';

  if (url.includes('?')) {
    [urlLocation, urlQuery] = url.split('?');

    if (urlQuery.includes('#')) {
      [urlQuery, urlAnchor] = urlQuery.split('#');
    }
  } else if (url.includes('#')) {
    [urlLocation, urlAnchor] = url.split('#');
  }

  return [urlLocation, urlQuery, urlAnchor];
};

/**
 * Redirects to url
 * @param {string} url - url to redirect to
 * @param {string} customFrom - value to set as from parameter, otherwise is undefined and from will get set to page path will get set to from
 */
export const goToUrl = (url, customFrom) => {
  // fromParam gets set to customFrom if defined otherwise set to page path
  const fromParam = customFrom || getFromParameter();
  // If there's a from parameter, set it as the first parameter so it's handled correctly by other web teams
  const queries = fromParam ? [`from=${fromParam}`] : [];

  const prevParams = window.location.search;
  // remove ? from beginning of string
  if (prevParams) queries.push(prevParams.substring(1));

  const [urlLoc, urlQuery, urlAnchor] = getLocationAndExtras(url);

  if (urlQuery) queries.push(urlQuery);

  const joinedQuery = queries.join('&');

  const query = joinedQuery ? `?${joinedQuery}` : '';
  const anchor = urlAnchor ? `#${urlAnchor}` : '';
  const newUrl = `${urlLoc}${query}${anchor}`;

  window.location.assign(newUrl);
};

/**
 * Handles CTA redirect flow
 *
 * @param {Object} params - query params to be passed to the URL
 * @param {Object} user - object containing user's entitlement data
 * @param {string} componentUrl - URL to redirect to
 * @param {Object} ctaFields - object with fields used to get CTA path based on entitlement state
 * @param {string} from - the value to be passed to the "from" parameter representing the hype program
 * @param {Object} cartAbandonment - object containing cart abandonment data
 * @param {boolean} [passCustomFromToGoToUrl=false] - determines whether or not to pass "from" parameter to goToUrl function, defaults to false
 * @param {boolean} [passUrlAsProgram=true=true] - whether or not the program will fallback to the page url, defaults to true
 * @returns {void}
 */
export const ctaRedirect = ({
  params,
  user,
  componentUrl,
  ctaFields,
  from,
  cartAbandonment,
  passCustomFromToGoToUrl = false,
  passUrlAsProgram = true,
}) => {
  const { cartAbandonmentCopy } = ctaFields;
  const cartAbandonmentHref = get(cartAbandonmentCopy, 'href', null);

  const url =
    cartAbandonment && cartAbandonmentHref
      ? cartAbandonmentHref
      : getCtaPath({ user, componentUrl, ctaFields });

  /* istanbul ignore if */
  if (url && !url.startsWith('/signup')) {
    // if passCustomFromToGoToUrl is set to true, then no from parameter is passed in
    // else from value is passed in
    if (passCustomFromToGoToUrl) {
      goToUrl(url, from);
    } else {
      goToUrl(url);
    }
  } else {
    goToSignup(params, url, user, from, passUrlAsProgram);
  }
};

/**
 * Checks for the locale of a page.
 * @param {string} langCode like 'es-us' or 'es'
 * @return {boolean}
 */
export const isLanguage = (langCode) =>
  isBrowser() && window.location.pathname.includes(langCode);

/**
 * Get download app url for mobile, based on current mobile OS
 * @return {string}
 */
export const getDownloadAppLink = () => {
  if (!mobileDetect().mobile()) {
    return null;
  }

  const os = mobileDetect().os();
  return mobileAppUrls[os];
};

/**
 * @param {string} url
 * @return {string}
 */
export const getHostname = (url) => {
  if (isBrowser()) {
    return window.location.hostname;
  }

  try {
    const urlObject = new URL(url);
    return urlObject.hostname;
  } catch (error) {
    DefaultLogger.error(`Caught error in getHostname for url: ${url}`, error);
    return getHostnameByEnvironment();
  }
};

/**
 * @param {string} url
 * @return {string}
 */
export const getProtocol = (url) => {
  if (isBrowser()) {
    return window.location.protocol;
  }

  try {
    const urlObject = new URL(url);
    return urlObject.protocol;
  } catch (error) {
    DefaultLogger.error(`Caught error in getProtocol for url: ${url}`, error);
    return 'https:';
  }
};

/**
 * @param {string} url
 * @return {string}
 */
export const getPath = (url) => {
  if (isBrowser()) {
    return window.location.pathname;
  }

  try {
    const urlObject = new URL(url);
    return urlObject.pathname;
  } catch (error) {
    return null;
  }
};

/**
 * @param {string} path
 * @return boolean
 */
export const isPageRequest = (path) => {
  const isAsset = path.startsWith(assetPrefix);
  const isApi = path.startsWith(apiPrefix);
  const isHealthCheck = path === '/health_check';
  const isNextJs = path.startsWith('/_next');
  const isDeeplink = path === '/open';
  const hasExtension = path.includes('.'); // any request with .php, .js, etc should be ignored

  return !(
    isHealthCheck ||
    isNextJs ||
    isAsset ||
    isApi ||
    hasExtension ||
    isDeeplink
  );
};

export const shouldRedirectStartPageToHome = ({
  allowSubscriberTraffic = false,
  isSubscriber = false,
  pathname = '',
} = {}) =>
  /^\/start(\/.*)?/g.test(pathname) && isSubscriber && !allowSubscriberTraffic;

// Helper function to determine if the current device should use the connected
// login instead of the standard login form and redirect if so.
export const shouldUseConnectedLogin = (connectedAuthFeatureFlag) => {
  return (
    mobileDetect().match(
      `${USER_AGENTS_REGEX.TESLA}|${USER_AGENTS_REGEX.GM}`
    ) && connectedAuthFeatureFlag
  );
};

// If a device should use the connected login experience instead of the standard
// login form. Redirect them to the connected app login experience.
export const useConnectedLoginDetectAndRedirect = () => {
  window.location.assign('/connected-app');
};
