import * as url from 'url';

import get from 'lodash/get';

import { HIT_EVENT } from './constants';
import { trackEvent } from './metricsTracker';
import { getMetricsDeviceType, getMetricsPageName } from './utils';

import { getDeviceType } from '!app/lib/deviceUtils';

/**
 * Fires a driver click event.
 *
 * @see https://wiki.hulu.com/display/RAD/Driver+Click+HIT
 * @param {string} target Target of click, example 'navigation'
 */
export function fireDriverClick(target) {
  const pageName = getMetricsPageName();
  const deviceType = getMetricsDeviceType();

  trackEvent(HIT_EVENT.DRIVER_CLICK.TYPE, {
    hit_version: HIT_EVENT.DRIVER_CLICK.VERSION,
    driver_page: pageName,
    driver_location: target,
    driver_id: `site:${deviceType}:${pageName}:${target}`,
  });
}

/**
 * Fires a user interaction event.
 *
 * @see https://minerva.test.hulu.com/hits/user_interaction/details/primary
 * @param {string} element Target element, example 'masthead_anchor'
 * @param {string} action Action, example 'click'
 * @param {string} [interactionType='click'] Accepts click, swipe, touch, tap, etc.
 * @param {boolean} [sendDeviceType = true] Boolean to determine appending device type to action specifier
 * @param {string} [targetDisplayName = '']
 */
export function fireUserInteraction(
  element,
  action,
  interactionType = 'click',
  sendDeviceType = true,
  targetDisplayName = ''
) {
  const deviceType = getMetricsDeviceType();
  trackEvent(HIT_EVENT.USER_INTERACTION.TYPE, {
    hit_version: HIT_EVENT.USER_INTERACTION.VERSION,
    interaction_type: interactionType,
    element_specifier: element,
    action_specifier: sendDeviceType ? `${action}:${deviceType}` : action,
    target_display_name: targetDisplayName,
    curr_page_uri: window?.location?.href || '',
  });
}

/**
 * Fires a subscription error event.
 *
 * @see https://minerva.prod.hulu.com/hits/subscription_error/details/primary
 * @param {string} reason reason for the error
 * @param {string} screenName Name of the screen provided by sufo-redux
 */
export function fireSubscriptionError(reason, screenName) {
  trackEvent(HIT_EVENT.SUBSCRIPTION_ERROR.TYPE, {
    hit_version: HIT_EVENT.SUBSCRIPTION_ERROR.VERSION,
    reason,
    screen_name: screenName,
  });
}

/**
 * Fires subscription start event
 * @see https://minerva.prod.hulu.com/hits/subscription_start/details/primary
 * @param {string} flowName The name of the flow the user is following
 */
export function fireSubscriptionStart(flowName) {
  trackEvent(HIT_EVENT.SUBSCRIPTION_START.TYPE, {
    hit_version: HIT_EVENT.SUBSCRIPTION_START.VERSION,
    flow_name: flowName,
  });
}

/**
 * Fires subscription step start event
 * @see https://minerva.prod.hulu.com/hits/subscription_step_start/details/primary
 * @param {string} flowName The name of the flow the user is following.
 * @param {string} screenName Name of the screen provided by sufo-redux
 */
export function fireSubscriptionStepStart(flowName, screenName) {
  trackEvent(HIT_EVENT.SUBSCRIPTION_STEP_START.TYPE, {
    hit_version: HIT_EVENT.SUBSCRIPTION_STEP_START.VERSION,
    flow_name: flowName,
    screen_name: screenName,
  });
}

/**
 * Fires a utag link event. Use for GA events.
 *
 * @see https://wiki.hulu.com/display/PT/Utag+Schema+Documentation
 * @param {object} dataObject Check see tag
 */
export function fireUtagLink(dataObject, options) {
  const pageName = `_${getMetricsPageName()}`;
  const disablePageName = get(options, 'disablePageName', false);
  const deviceType = getDeviceType();
  const utagData = Object.assign(dataObject, {});

  if (!disablePageName && !utagData.event_name.includes(pageName)) {
    utagData.event_name += pageName;
  }
  utagData.device_category = deviceType;

  if (global.window.utag) {
    global.window.utag.link(dataObject);
  }
}

/**
 * @param {string} event the name of the event
 * @param {string} hitVersion: the version of the event
 * @param {string} path
 * @param {object} query
 */
function sendDeeplinkHit(event, hitVersion, path, query) {
  let webSource = 'web_universal_deep_linking';
  const docReferrer = get(query, 'doc_referrer', '');
  const postfix = docReferrer ? url.parse(docReferrer).host : '';
  const source = get(query, 'utm_source', '');
  if (postfix) webSource = `${webSource}_${postfix}`;
  trackEvent(event, {
    hit_version: hitVersion,
    source: webSource.toLowerCase(),
    referrer_url: docReferrer,
    deeplink: path,
    utm_medium: get(query, 'utm_medium', ''),
    utm_source: source,
    utm_campaign: get(query, 'utm_campaign', ''),
    jobid: get(query, 'jobid', null),
    plus_app: get(query, 'plus_app', ''),
    cmc: get(query, 'cmc', ''),
    cmp: get(query, 'cmp', ''),
    attr_source: source,
    is_deferred: get(query, 'isAppsFlyer', false),
  });
}

/**
 * Fires a Deeplink Launch Error HIT
 *
 * @see https://wiki.hulu.com/display/RAD/Deeplink+Launch+Error+HIT
 * @param {string} path: the url path
 * @param {object} query: the url query
 */
export function fireDeeplinkLaunchError(path, query = {}) {
  sendDeeplinkHit(
    HIT_EVENT.DEEPLINK_LAUNCH_ERROR.TYPE,
    HIT_EVENT.DEEPLINK_LAUNCH_ERROR.VERSION,
    path,
    query
  );
}

/**
 * Fires a Deeplink Launch HIT
 *
 * @see https://wiki.hulu.com/display/RAD/Deeplink+Launch+HIT
 * @param {string} path: the url path
 * @param {object} query: the url query
 */
export function fireDeeplinkLaunch(path, query = {}) {
  sendDeeplinkHit(
    HIT_EVENT.DEEPLINK_LAUNCH.TYPE,
    HIT_EVENT.DEEPLINK_LAUNCH.VERSION,
    path,
    query
  );
}
