import { mobileDetect, isBrowser } from '!app/lib/environment';

const _osConfig = [
  { matchOn: 'Win', name: 'Windows' },
  { matchOn: 'Mac', name: 'Mac' },
  { matchOn: 'X11', name: 'Unix' },
  { matchOn: 'Linux', name: 'Linux' },
];

const _browserConfig = [
  { matchOn: 'Opera', name: 'Opera' },
  { matchOn: 'OPR', name: 'Opera' },
  { matchOn: 'MSIE', name: 'Microsoft Internet Explorer' },
  { matchOn: 'Chrome', name: 'Chrome' },
  { matchOn: 'Safari', name: 'Safari' },
  { matchOn: 'Firefox', name: 'Firefox' },
  { matchOn: 'Chromium', name: 'Chromium' },
  { matchOn: 'Seamonkey', name: 'Seamonkey' },
];

const _deviceConfig = [
  {
    matchOn: /(ipad|itouch|iphone|ipod)/i,
    identifier: 'iOS',
    deviceType: _isTabletOrPhone,
  },
  { matchOn: /Xbox One/i, identifier: 'xboxOne', deviceType: 'Console' },
  { matchOn: /Xbox/i, identifier: 'xbox', deviceType: 'Console' },
  { matchOn: /Android/i, identifier: 'android', deviceType: _isTabletOrPhone },
  {
    matchOn: /Windows Phone/i,
    identifier: 'windowsPhone',
    deviceType: 'Phone',
  },
  { matchOn: /GoogleTV/i, identifier: 'googleTV', deviceType: 'SetTop' },
  { matchOn: /Boxee/i, identifier: 'boxee', deviceType: 'SetTop' },
  { matchOn: /Skyfire/i, identifier: 'skyfire', deviceType: 'SetTop' },
  {
    matchOn: /(Playbook|BlackBerry);/i,
    identifier: 'blackberry',
    deviceType: 'Phone',
  },
  { matchOn: /(BB10);/i, identifier: 'blackberry10', deviceType: 'Phone' },
  {
    matchOn: /PlayStation Vita/i,
    identifier: 'psVita',
    deviceType: 'Handheld',
  },
  { matchOn: /Silk\/1/i, identifier: 'kindle', deviceType: _isTabletOrPhone },
  {
    matchOn: /Silk\/[2-9]/i,
    identifier: 'kindle',
    deviceType: _isTabletOrPhone,
  },
  { matchOn: /BNTV250/i, identifier: 'nook', deviceType: 'Tablet' },
  { matchOn: /PLAYSTATION 3/i, identifier: 'ps3', deviceType: 'Console' },
  { matchOn: /PlayStation 4/i, identifier: 'ps4', deviceType: 'Console' },
  { matchOn: /Nintendo WiiU/i, identifier: 'wiiU', deviceType: 'Console' },
  {
    matchOn: /Mobile/i,
    identifier: 'unknown-mobile-browser',
    deviceType: 'Mobile',
  },
];

function _isTabletOrPhone() {
  const agent = mobileDetect().ua;
  return /(itouch|iphone|ipod)/i.test(agent) ||
    (/(mobile)/i.test(agent) && /android/i.test(agent))
    ? 'Phone'
    : 'Tablet';
}

function _getOS() {
  const agent = mobileDetect().ua;
  for (let i = 0; i < _osConfig.length; i++) {
    if (agent.indexOf(_osConfig[i].matchOn) >= 0) {
      return _osConfig[i].name;
    }
  }

  return 'Unknown OS';
}

function _getOSVersion() {
  const agent = mobileDetect().ua;
  if (/^Mozilla\/\d+\.\d+ \(([^)]+)\)/.test(agent)) {
    const osStr = RegExp.$1;
    if (/Windows NT ([^;]+)/.test(osStr)) {
      return RegExp.$1;
    }
    if (/Mac OS X ([^;]+)/.test(osStr)) {
      return RegExp.$1;
    }
    return osStr;
  }

  return '';
}

function _getBrowser() {
  const agent = mobileDetect().ua;
  for (let i = 0; i < _browserConfig.length; i++) {
    if (agent.indexOf(_browserConfig[i].matchOn) >= 0) {
      return _browserConfig[i].name;
    }
  }
  if (isBrowser()) {
    return global.navigator.appName;
  }
  return null;
}

export function getDeviceType() {
  const agent = mobileDetect().ua;
  let device;
  for (let i = 0; i < _deviceConfig.length; i++) {
    device = _deviceConfig[i];
    if (device.matchOn.test(agent)) {
      return typeof device.deviceType === 'function'
        ? device.deviceType()
        : device.deviceType;
    }
  }

  return 'Desktop';
}

export function getDevice() {
  return {
    deviceType: getDeviceType(),
    browser: _getBrowser(),
    os: _getOS(),
    osVersion: _getOSVersion(),
  };
}

export function appendDeviceInfo() {
  const device = getDevice();
  const info = [];
  const NOT_FOUND_INDEX = -1;
  if (device.deviceType === 'Phone' || device.deviceType === 'Tablet') {
    info.push('device-is-mobile');
    info.push('device-not-desktop');
  } else {
    info.push('device-not-mobile');
    info.push('device-is-desktop');
  }
  info.push(`browser-${device.browser.toLowerCase()}`);
  info.push(`os-${device.os.toLowerCase()}`);
  info.push(`osv-${device.osVersion.toLowerCase()}`);

  if (
    document.getElementsByTagName('html')[0].className.indexOf('device-is') ===
    NOT_FOUND_INDEX
  ) {
    document.getElementsByTagName('html')[0].className += ` ${info.join(' ')}`;
  }
}
