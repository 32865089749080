import cookie from 'js-cookie';
import get from 'lodash/get';

import { isBrowser } from '!app/lib/environment';

const getCookieFromString = (cookieId, cookieString) => {
  const value = cookieString.match(`(^|[^;]+)\\s*${cookieId}\\s*=\\s*([^;]+)`);
  return value ? value.pop() : '';
};

function getCookie(cookieId, ctx = {}) {
  if (isBrowser()) {
    return cookie.get(cookieId);
  }

  const cookieString = get(ctx, 'req.headers.cookie', null);

  return cookieString ? getCookieFromString(cookieId, cookieString) : null;
}

function setCookie(cookieId, value, options) {
  if (!isBrowser()) return;
  cookie.set(cookieId, value, {
    domain: window.location.hostname.includes('huluqa')
      ? '.huluqa.com'
      : '.hulu.com',
    path: '/',
    expires: 30,
    ...options,
  });
}

function eraseCookie(cookieId) {
  if (!isBrowser()) return;
  cookie.remove(cookieId);
}

function getCookies(cookieIds, ctx = {}) {
  return cookieIds.map((cookieId) => [cookieId, getCookie(cookieId, ctx)]);
}

export { getCookie, setCookie, eraseCookie, getCookies };
