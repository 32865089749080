import { getCookie } from '!app/lib/cookies';

export const HULU_ONE_TRUST_GROUP = 'SPD_BG';
export const OPTANON_CONSENT_COOKIE_ID = 'OptanonConsent';

export const OneTrustProdScript = (
  <script
    src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
    data-document-language="true"
    type="text/javascript"
    charset="UTF-8"
    data-domain-script="6d45ba73-6015-4447-9ccf-e722d2959983"
    defer
  />
);

export const OneTrustNonProdScript = (
  <script
    src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
    data-document-language="true"
    type="text/javascript"
    charset="UTF-8"
    data-domain-script="c270fd7d-b546-4e84-99b0-e480304e7727"
    defer
  />
);

export function isOptanonConsentCookieSetForHulu() {
  const optanonConsentCookie = getCookie(OPTANON_CONSENT_COOKIE_ID);

  if (
    optanonConsentCookie &&
    optanonConsentCookie.includes(`${HULU_ONE_TRUST_GROUP}:1`)
  ) {
    return true;
  }
  return false;
}

export function isOptanonConsentCookieSet() {
  const optanonConsentCookie = getCookie(OPTANON_CONSENT_COOKIE_ID);

  return Boolean(optanonConsentCookie);
}
