import get from 'lodash/get';

/**
 * Returns the relevant cart abandonment copy based on what data we have
 * from the cart abandonment cookie and SiteConfig.
 *
 * @param {Object} cartAbandonmentValues Values from withCartAbandonmentConfig HOC
 * @param {Object} copyOptions Cart Abandonment values from HO
 * @returns {string} Copy to display for Cart Abandoners, tokenized
 */
const tokenizeCartAbandonmentCopy = (cartAbandonmentValues, copyOptions) => {
  if (!cartAbandonmentValues || !copyOptions) return null;

  const { plan, name } = cartAbandonmentValues;
  const planAndNameCopy = get(copyOptions, 'planAndNameCopy', null);
  const nameCopy = get(copyOptions, 'nameCopy', null);
  const planCopy = get(copyOptions, 'planCopy', null);
  const defaultCopy = get(copyOptions, 'defaultCopy', null);

  // if we have both the name and plan, we want to use both as first priority.
  if (name && plan && planAndNameCopy) {
    return planAndNameCopy
      .replace(new RegExp('{{user_name}}', 'g'), name)
      .replace(new RegExp('{{plan_name}}', 'g'), plan);
  }

  // otherwise, check for name and plan respectively
  if (name && nameCopy) {
    return nameCopy.replace(new RegExp('{{user_name}}', 'g'), name);
  }

  if (plan && planCopy) {
    return planCopy.replace(new RegExp('{{plan_name}}', 'g'), plan);
  }

  return defaultCopy;
};

export { tokenizeCartAbandonmentCopy };
