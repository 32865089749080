import { SERIES, MOVIE, NETWORK, GENRE, HUB } from '!app/lib/constants';

const browseTypes = [SERIES, MOVIE, NETWORK, GENRE, HUB];

/**
 * Returns the type of browse page
 * @return {string|null} either the page type or null
 */
export function getBrowsePageType(path) {
  const tokens = path.split('/');
  return browseTypes.includes(tokens[1]) ? tokens[1] : null;
}
