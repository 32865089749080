export const HIT_EVENT = {
  DEEPLINK_LAUNCH: { TYPE: 'deeplink_launch', VERSION: '1.1.0' },
  DEEPLINK_LAUNCH_ERROR: { TYPE: 'deeplink_launch_error', VERSION: '1.5.0' },
  DRIVER_CLICK: { TYPE: 'driver_click', VERSION: '1.0.0' },
  PAGE_IMPRESSION: { TYPE: 'page_impression', VERSION: '1.7.0' },
  SUBSCRIPTION_ERROR: { TYPE: 'subscription_error', VERSION: '1.4.0' },
  SUBSCRIPTION_STEP_START: { TYPE: 'subscription_step_start', VERSION: '1.4' },
  SUBSCRIPTION_START: { TYPE: 'subscription_start', VERSION: '1.2' },
  USER_INTERACTION: { TYPE: 'user_interaction', VERSION: '2.17.0' },
};

export const HIT_SUBSCRIBER_ERROR_REASON = {
  SUBSCRIBER_FOUND: 'subscriber_found',
  USER_INVALID_INPUT: 'user_invalid_input',
};

// eslint-disable-next-line no-magic-numbers
export const SCROLL_QUARTER_PERCENTILE = [0, 25, 50, 75, 100];
